import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  Optional,
  Host,
  OnInit,
  SimpleChanges,
  HostBinding,
} from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
// import { ScopeComponent } from '../../_layout/card/card.component';
import {
  FormGroup,
  ControlContainer,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ValidationErrorsComponent } from '../validation-errors/validation-errors.component';
import { ReadonlyDirective } from 'src/app/directives/readonly.directive';
import { IconBoxComponent } from '../../_elements/icon-box/icon-box.component';

@Component({
  selector: 'icon-value-field',
  standalone: true,
  templateUrl: './icon-value-field.component.html',
  styleUrls: ['icon-value-field.component.scss'],
  imports: [TranslateModule, IconBoxComponent],
  encapsulation: ViewEncapsulation.None,
})
export class IconValueFieldComponent {
  @Input() public icon: string;
}
